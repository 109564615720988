import React from 'react';

import { BusinessErrors } from '@keplr/api-catalog-errors';

import Dialog from '~/src/common/components/Dialog';
import UnavailableCouponReasonsList from '~/src/common/components/UnavailableCouponDialog/UnavailableCouponDialog';
import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import I18n from '~/src/common/services/I18n';

/**
 * Erreur E_21_0003 - Le code promo a déjà été appliqué
 * Lors de l'application d'un code promo, le code promo a déjà été appliqué au cart
 */

export default {
  code: BusinessErrors.PromoCodes.AlreadyUsed.code,
  handler: ({ error }: HandlerPayload) => {
    const { message } = error;

    // Le promo code n'est pas encore disponible au sein de l'erreur
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
    const promoCode = error.promoCodeId;

    if (message != null && message !== '' && promoCode != null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
      const { promoCodeId } = error.meta || {};

      Dialog.error({
        title: I18n.translate({
          value: 'checkout-screen.discount-section.unavailable-code-promo-coupons-title',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
          promoCode,
        }),
        description: (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument  -- auto-ignored when updating eslint
          <UnavailableCouponReasonsList reasons={[message.replace(promoCodeId, promoCode)]} />
        ),
      });
    } else {
      Dialog.error({
        title: I18n.t('errors.default-title'),
        description: message,
      });
    }
  },
};
