import { BusinessErrors } from '@keplr/api-catalog-errors';

import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import { showNiceModal } from '~/src/common/services/ModalsManager';

export default {
  code: BusinessErrors.Catalog.Referral.Anonymous.code,
  handler: (payload: HandlerPayload) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
    const { referralCode } = payload.error.meta || {};

    // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
    showNiceModal('account-management-modal', { referralCode, showSignup: true });
  },
};
